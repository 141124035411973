import React from 'react';
import { useTransition, animated } from 'react-spring';
import { CenteredContainer, LoaderAnimation } from '../common/styles';

// const messages = ['Counting stars', 'Analyzing repos', 'Filtering releases'];

const messages = {
  verbs: ['counting', 'analyzing', 'filtering', 'ordering', 'merging'],
  objects: ['stars', 'repositories', 'releases', 'commits', 'prs'],
};

function random(arr: string[]): string {
  return arr[Math.ceil(Math.random() * arr.length - 1)];
}

function Loader() {
  const [message, setMessage] = React.useState(
    random(messages.verbs) + ' ' + random(messages.objects)
  );

  React.useEffect(() => {
    const id = setInterval(() => {
      setMessage(random(messages.verbs) + ' ' + random(messages.objects));
    }, 1500);

    return () => clearInterval(id);
  }, []);

  const transitions = useTransition(message, null, {
    config: { duration: 200 },
    from: { position: 'absolute', opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return (
    <CenteredContainer>
      <LoaderAnimation>
        <div />
      </LoaderAnimation>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        {transitions.map(({ item, key, props }) => (
          <animated.p
            key={key}
            style={{ ...props, textTransform: 'lowercase' }}
          >
            {item}
          </animated.p>
        ))}
      </div>
    </CenteredContainer>
  );
}

export default Loader;
