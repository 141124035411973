export const FONT_SIZE = {
  BIG: '1.4em',
  NORMAL: '1em',
  SMALL: '0.8em',
};

export const COLORS = {
  ACCENT: 'rgb(3, 102, 214)',
  ACCENT_MEDIUM: 'rgba(3, 102, 214, 0.45)',
  ACCENT_LIGHT: 'rgba(3, 102, 214, 0.3)',
  GREY_LIGHT: 'rgb(80, 80, 80)',
  GREY: 'rgb(51, 51, 51)',
};

export const BASEPATH = process.env.REACT_APP_BASEPATH;
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const BASEPATH_OAUTH = process.env.REACT_APP_BASEPATH_OAUTH;
