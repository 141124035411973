import React from 'react';
import { getLocalStorage, setLocalStorage } from './helpers';

interface FetchParam extends RequestInit {
  responseType?: 'TEXT' | 'JSON';
}
export function useFetch(url: string, params: FetchParam) {
  const [isLoading, setIsLoading] = React.useState(true);
  const [data, setData] = React.useState(undefined as any | undefined);
  const [error, setError] = React.useState(undefined as string | undefined);

  React.useEffect(() => {
    async function get() {
      try {
        const res = await fetch(url, params);
        if (res.ok) {
          let data;
          if (params.responseType === 'TEXT') {
            data = await res.text();
          } else {
            data = await res.json();
          }
          setData(data);
          setIsLoading(false);
        } else {
          throw new Error(`${res.statusText} (Code: ${res.status})`);
        }
      } catch (error) {
        setError(error.message);
        setIsLoading(false);
      }
    }

    get();
  }, [params, url]);

  return { data, isLoading, error };
}

export function useLocalStorage<T>(
  key: string,
  initialValue: T,
  expireTimeInMinutes?: number
): [T, (value: T) => void] {
  const [storedValue, setStoredValue] = React.useState<T>(() => {
    try {
      const storedValue = getLocalStorage(key, expireTimeInMinutes);
      return storedValue !== null ? storedValue : initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });

  React.useEffect(() => {
    function setValue(value: T): void {
      try {
        const valueToStore =
          value instanceof Function ? value(storedValue) : value;
        setLocalStorage(key, valueToStore, expireTimeInMinutes);
      } catch (error) {
        console.log(error);
      }
    }

    setValue(storedValue);
  }, [storedValue, key, expireTimeInMinutes]);

  return [storedValue, setStoredValue];
}
