import React from 'react';
import styled from 'styled-components';
import { Repository } from '../common/interfaces';
import dayjs from 'dayjs';
import { FONT_SIZE, COLORS } from '../common/constants';

interface RepositoryProps {
  repository: Repository;
}

function RepositoryComponent(props: RepositoryProps) {
  const { repository } = props;
  const { latestRelease } = repository;

  if (!latestRelease) {
    return null;
  }

  const dayReleased = latestRelease.publishedAt.substring(0, 10);
  const today = dayjs();
  const diff = today.diff(dayjs(dayReleased), 'd');
  let releaseLabel = '';
  switch (diff) {
    case 0:
      releaseLabel = ' · released today';
      break;
    case 1:
      releaseLabel = ` · released 1 day ago on ${dayReleased}`;
      break;

    default:
      releaseLabel = ` · released ${diff} days ago on ${dayReleased}`;
      break;
  }

  const version =
    latestRelease.tagName.charAt(0) === 'v'
      ? latestRelease.tagName.substring(1)
      : latestRelease.tagName;

  return (
    <Container onClick={() => window.open(repository.url, '_blank')}>
      <Title>
        <a
          title={repository.description}
          onClick={(e) => e.stopPropagation()}
          href={repository.url}
          rel="noopener noreferrer"
          target="_blank"
        >
          {repository.name}
        </a>
      </Title>
      <Text>
        <a
          onClick={(e) => e.stopPropagation()}
          href={latestRelease.url}
          rel="noopener noreferrer"
          target="_blank"
        >
          {version}
        </a>
        {releaseLabel}
      </Text>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding: 10px;
  background-color: #f7f7f7;
  box-shadow: 2px 2px 0px #e0e0e0;
  transition: all ease-in 100ms;
  cursor: pointer;

  font-family: monospace;
  text-decoration: none;

  &:hover {
    box-shadow: 4px 4px 0px #e0e0e0;
    transform: translateY(-2px);
  }
`;

const Title = styled.h1`
  font-size: ${FONT_SIZE.BIG};
  margin: 0;
  margin-bottom: 5px;

  a {
    padding: 0;
    color: ${COLORS.ACCENT};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Text = styled.p`
  margin: 0;

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export default RepositoryComponent;
