import React from 'react';
import styled from 'styled-components';

function Footer() {
  return (
    <FooterStyled>
      <span>
        {'made with love by '}
        <a
          href="https://www.federicomoretti.dev"
          target="_blank"
          rel="noopener noreferrer"
        >
          federico moretti
        </a>
      </span>
    </FooterStyled>
  );
}

const FooterStyled = styled.div`
  display: flex;
  justify-content: center;
  padding: 15px 0 5px;

  span {
    opacity: 0.5;
    font-size: 12px;
    text-transform: lowercase;
  }
`;

export default Footer;
