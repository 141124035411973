export function queryRepositories({
  login,
  count,
  after,
}: {
  login: string;
  count?: number;
  after?: string;
}) {
  const defaultCount = count ? count : 20;
  const afterParam = after ? `after: "${after}"` : '';

  return `query {
  user(login: "${login}") {
    starredRepositories(first: ${defaultCount} ${afterParam}) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      nodes {
        name
        url
        description
        latestRelease {
          name
          tagName
          publishedAt
          isPrerelease
          isDraft
          url
        }
      }
    }
  }
}
`;
}
