import React from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import { CLIENT_ID, BASEPATH_OAUTH } from '../common/constants';
import { CenteredContainer } from '../common/styles';

function Authenticate(
  props: RouteComponentProps & { setToken: (token: string) => void }
) {
  const [error, setError] = React.useState(undefined as string | undefined);
  const { location, setToken } = props;

  React.useEffect(() => {
    async function getToken() {
      const href = location && location.href;
      if (href) {
        try {
          const url = new URL(href);
          const code = url.searchParams.get('code');
          const oauthState = url.searchParams.get('state');
          const storedOauthState = localStorage.getItem('oauthState');

          if (oauthState !== storedOauthState)
            throw new Error('Oauth state is different!');

          if (code) {
            const tokenUrl = `${BASEPATH_OAUTH}/oauth/github`;
            const params = `?client_id=${CLIENT_ID}&code=${code}`;
            const res = await fetch(tokenUrl + params);
            const obj = await res.json();
            if (obj.token) {
              setToken(obj.token);
              navigate('home');
            }
          }
        } catch (error) {
          setError('Sorry, there has been an error, retry later!');
          console.log(error);
        }
      }
    }

    getToken();
  }, [location, setToken]);

  return (
    <CenteredContainer>
      {error ? <p>{error}</p> : <p>Authenticating...</p>}
    </CenteredContainer>
  );
}

export default Authenticate;
