import React from 'react';
import { Router } from '@reach/router';
import styled from 'styled-components';
import { useLocalStorage } from './common/hooks';
import Authenticate from './pages/Authenticate';
import Home from './pages/Home';
import Login from './pages/Login';
import Footer from './components/Footer';
import { COLORS } from './common/constants';

function App() {
  const [token, setToken] = useLocalStorage(
    'token',
    undefined as string | undefined
  );

  return (
    <Container>
      <Body>
        <Title>updated repositories</Title>
        <RouterStyled>
          <Login path="login" />
          <Home path="home" token={token} default />
          <Authenticate path="authenticate" setToken={setToken} />
        </RouterStyled>
      </Body>
      <Footer />
    </Container>
  );
}

const RouterStyled = styled(Router)`
  display: flex;
  flex: 1 0 auto;
  width: 100%;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

const Body = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Title = styled.h1`
  display: inline-block;
  padding: 10px;
  margin: 20px auto;
  font-size: 26px;
  color: ${COLORS.ACCENT};
  background-color: ${COLORS.ACCENT_LIGHT};
  text-align: center;
  box-shadow: 4px 4px 0px ${COLORS.ACCENT};
`;

export default App;
