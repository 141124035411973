import React from 'react';
import dayjs from 'dayjs';
import { useLocation } from '@reach/router';
import { RouteComponentProps, navigate } from '@reach/router';
import { Repository, RepositoriesQuery } from '../common/interfaces';
import RepositoryComponent from '../components/Repository';
import {
  convertGraphQLQuery,
  fetch2,
  getLocalStorage,
  setLocalStorage,
} from '../common/helpers';
import { queryRepositories } from '../queries';
import { COLORS } from '../common/constants';
import { ReactComponent as Star } from '../icons/star.svg';
import Loader from '../components/Loader';
import {
  CenteredContainer,
  Container,
  Infos,
  StarsCount,
  DaysInputText,
  DaysInput,
  RepositoriesContainer,
} from '../common/styles';

function filterRepositories(
  repositories: Repository[],
  daysPast: number
): Repository[] {
  const filteredRepositories = repositories
    .filter((r) => r.latestRelease)
    .filter((repo) => {
      if (repo.latestRelease) {
        const releaseDate = dayjs(repo.latestRelease.publishedAt);
        const releaseDatePlus = releaseDate.add(daysPast, 'd');
        return releaseDatePlus > dayjs();
      }
      return true;
    });

  filteredRepositories.sort((repo1, repo2) => {
    const date1 = repo1.latestRelease!.publishedAt;
    const date2 = repo2.latestRelease!.publishedAt;
    return date1 > date2 ? -1 : 1;
  });

  return filteredRepositories;
}

async function getRepos(
  login: string,
  count: number,
  token: string,
  after?: string
): Promise<RepositoriesQuery> {
  const body = convertGraphQLQuery(queryRepositories({ login, count, after }));
  const res = await fetch2({
    url: 'https://api.github.com/graphql',
    method: 'POST',
    token: token,
    body,
  });
  return res;
}

interface State {
  repositoriesCount: number;
  repositories: Repository[];
  filteredRepositories: Repository[];
  isLoading: boolean;
  daysPast: number;
}

const initialState: State = {
  repositoriesCount: 0,
  repositories: [],
  filteredRepositories: [],
  isLoading: true,
  daysPast: 15,
};

type Action =
  | { type: 'SET_STORED_STATE'; daysPast: number }
  | {
      type: 'SET_REPOSITORIES';
      repositories: Repository[];
      repositoriesCount: number;
    }
  | { type: 'SET_LOADING'; isLoading: boolean }
  | { type: 'SET_DAYS_PAST'; daysPast: number }
  | { type: 'INCREASE_MESSAGE_INDEX' };

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'SET_REPOSITORIES':
      return {
        ...state,
        repositories: action.repositories,
        repositoriesCount: action.repositoriesCount,
        filteredRepositories: filterRepositories(
          action.repositories,
          state.daysPast
        ),
        isLoading: false,
      };
    case 'SET_LOADING':
      return { ...state, isLoading: action.isLoading };
    case 'SET_DAYS_PAST':
      setLocalStorage('daysPast', action.daysPast);

      return {
        ...state,
        daysPast: action.daysPast,
        filteredRepositories: filterRepositories(
          state.repositories,
          action.daysPast
        ),
      };
    case 'SET_STORED_STATE':
      return {
        ...state,
        daysPast: action.daysPast || state.daysPast,
      };

    default:
      throw new Error('Missing action!');
  }
}

function getReposQueriedFromSearch(search: string): number | null {
  const searchParams = new URLSearchParams(search);
  const param = searchParams.get('rq');
  const paramInt = param ? parseInt(param) : null;
  return typeof paramInt === 'number' && !isNaN(paramInt) && paramInt > 0
    ? paramInt
    : null;
}

function Home(props: RouteComponentProps & { token: string | undefined }) {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const {
    repositories,
    repositoriesCount,
    filteredRepositories,
    daysPast,
    isLoading,
  } = state;

  const location = useLocation();
  const reposQueriedFromSearch = getReposQueriedFromSearch(location.search);

  React.useEffect(() => {
    const daysPast = getLocalStorage('daysPast');

    dispatch({ type: 'SET_STORED_STATE', daysPast });
  }, []);

  React.useEffect(() => {
    async function query() {
      try {
        if (props.token) {
          let repositories: Repository[] = [];
          const user = await fetch2({
            url: 'https://api.github.com/user',
            method: 'GET',
            token: props.token,
          });

          const reposQueried = reposQueriedFromSearch ?? 100;
          let hasNextPage = true;
          let endCursor = '';
          let totalCount = 0;

          while (hasNextPage) {
            try {
              const res = await getRepos(
                user.login,
                reposQueried,
                props.token,
                endCursor
              );

              totalCount = res.data.user.starredRepositories.totalCount;
              repositories = [
                ...repositories,
                ...res.data.user.starredRepositories.nodes,
              ];
              endCursor = res.data.user.starredRepositories.pageInfo.endCursor;
              hasNextPage =
                res.data.user.starredRepositories.pageInfo.hasNextPage;
            } catch {
              continue;
            }
          }

          setLocalStorage('repositories', repositories, 15);
          dispatch({
            type: 'SET_REPOSITORIES',
            repositories,
            repositoriesCount: totalCount,
          });
        } else {
          navigate('login');
        }
      } catch (error) {
        navigate('login');
        console.log(error);
      }
    }

    if (repositories.length === 0) {
      const localRepositories = getLocalStorage('repositories', 15);
      if (localRepositories) {
        dispatch({
          type: 'SET_REPOSITORIES',
          repositories: localRepositories,
          repositoriesCount: localRepositories.length,
        });
      } else {
        query();
      }
    } else {
      dispatch({ type: 'SET_LOADING', isLoading: false });
    }
  }, [props.token, repositories.length]);

  function handleChangeDays(event: React.FormEvent<HTMLInputElement>) {
    const days = Number(event.currentTarget.value.substring(0, 3));
    if (Number.isInteger(days)) {
      dispatch({ type: 'SET_DAYS_PAST', daysPast: days });
    } else {
      dispatch({ type: 'SET_DAYS_PAST', daysPast: 0 });
    }
  }

  if (isLoading) return <Loader />;

  return (
    <>
      <Container>
        <Infos>
          <StarsCount>
            <Star
              stroke={COLORS.GREY_LIGHT}
              width="18"
              height="18"
              style={{ marginRight: '7px' }}
            />
            {repositoriesCount + ' stars'}
          </StarsCount>
          <DaysInputText>
            {filteredRepositories.length} updates in the last{' '}
            <DaysInput
              value={daysPast}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                handleChangeDays(e)
              }
            />{' '}
            days
          </DaysInputText>
        </Infos>

        {filteredRepositories && filteredRepositories.length > 0 ? (
          <RepositoriesContainer>
            {filteredRepositories.map((repo) => (
              <RepositoryComponent key={repo.url} repository={repo} />
            ))}
          </RepositoriesContainer>
        ) : (
          <CenteredContainer>
            <p>There are no repositories...</p>
          </CenteredContainer>
        )}
      </Container>
    </>
  );
}

export default Home;
