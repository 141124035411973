import { camelCase } from 'camel-case';
import { Map } from './interfaces';

export function objectAdapter(obj: Map<string>): Map<string> {
  return Object.entries(obj).reduce((acc: Map<string>, [key, value]) => {
    const newKey = camelCase(key);
    const renamed = {
      [newKey]: value,
    };
    return { ...acc, ...renamed };
  }, {});
}

export function convertGraphQLQuery(query: string) {
  const body = JSON.stringify(query).replace(/ +/, ' ').replace(/\\n/g, ' ');
  return '{"query": ' + body + '}';
}

interface Fetch2Params {
  url: string;
  method: 'POST' | 'GET';
  token?: string;
  body?: any;
}
export function fetch2({
  url,
  method,
  token,
  body,
}: Fetch2Params): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${token}`,
        },
        body,
      });
      const obj = await res.json();
      if (res.ok) {
        resolve(obj);
      } else {
        reject(res.statusText);
      }
    } catch (error) {
      reject(error);
    }
  });
}

export function getLocalStorage(
  key: string,
  expireTimeInMinutes?: number
): any {
  try {
    const item = window.localStorage.getItem(key);
    const json = item !== null ? JSON.parse(item) : null;
    if (json !== null && (!expireTimeInMinutes || json.expire > Date.now())) {
      return JSON.parse(json.value);
    } else return null;
  } catch (error) {
    console.error(error.message);
    return null;
  }
}

export function setLocalStorage(
  key: string,
  value: any,
  expireTimeInMinutes?: number
) {
  const expire = expireTimeInMinutes
    ? Date.now() + expireTimeInMinutes * 60 * 1000
    : null;
  const toStoreValue = { expire, value: JSON.stringify(value) };
  window.localStorage.setItem(key, JSON.stringify(toStoreValue));
}
