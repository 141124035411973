import styled from 'styled-components';
import { COLORS } from './constants';

export const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const LoaderAnimation = styled.div`
  width: 200px;
  height: 10px;
  box-shadow: 2px 2px 0px ${COLORS.ACCENT};
  background-color: ${COLORS.ACCENT_LIGHT};

  & > div {
    height: 10px;
    width: 20px;
    background-color: ${COLORS.ACCENT};
    animation: translate 4s linear infinite;
  }

  @keyframes translate {
    0% {
      transform: translateX(0px);
    }
    50% {
      transform: translateX(180px);
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 10px;

  @media (min-width: 750px) {
    width: 80%;
  }
`;

const infoQuery = '950px';

export const RepositoriesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(375px, 1fr));
  grid-gap: 10px;
`;

export const StarsCount = styled.p`
  display: flex;
  align-items: center;
  margin: 5px 0;
`;

export const DaysInputText = styled.p`
  display: flex;
  align-items: center;
  margin: 5px 0;

  @media (min-width: ${infoQuery}) {
    justify-self: end;
  }
`;

export const Infos = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin: 5px;

  @media (min-width: ${infoQuery}) {
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  }
`;

export const DaysInput = styled.input`
  border: none;
  font-size: 14px;
  padding: 0;
  text-align: center;
  margin: 0 5px;
  background-color: #f7f7f7;
  display: inline-block;
  width: 2em;
  transform: translateY(1px);
  border-bottom: 2px solid ${COLORS.ACCENT};
  outline: none;
`;
