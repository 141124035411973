import React from 'react';
import { RouteComponentProps } from '@reach/router';
import styled from 'styled-components';
import uuid from 'uuid/v4';
import { FONT_SIZE, COLORS, BASEPATH, CLIENT_ID } from '../common/constants';
import { CenteredContainer } from '../common/styles';

function Login(_props: RouteComponentProps) {
  async function login() {
    const redirect = BASEPATH + '/authenticate';
    const scopes = 'user%20';
    const random = uuid();
    localStorage.setItem('oauthState', random);

    const params =
      `?response_type=code&client_id=${CLIENT_ID}&` +
      `scope=${scopes}&state=${random}&redirect_uri=${redirect}`;
    const url = 'https://github.com/login/oauth/authorize' + params;
    window.location.href = url;
  }

  return (
    <CenteredContainer>
      <Button onClick={login}>login with github</Button>
    </CenteredContainer>
  );
}

const Button = styled.button`
  background-color: ${COLORS.ACCENT_LIGHT};
  padding: 10px;
  border: none;
  cursor: pointer;
  outline: none;
  transition: all ease-in 100ms;
  box-shadow: 2px 2px 0px ${COLORS.ACCENT};

  color: ${COLORS.ACCENT};
  font-size: ${FONT_SIZE.BIG};
  text-align: center;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 4px 4px 0px ${COLORS.ACCENT};
  }
`;

export default Login;
